<template>
  <div>
    <form @submit.prevent="onSubmit()">
      <Section>
        <Container>
          <h2 class="section-title">Niveau de saisie</h2>
          <Radio v-if="saisie_levels"
            id="saisie_niveau_uid"
            :items="saisie_levels"
            @update:modelValue="toggleIsOrgane($event)" />

          <SelectExtended v-show="isOrgane"
            id="organe"
            label="Organe"
            :apiParams="{ sort: 'valeur.ASC' }"
            optionKey="id"
            optionValue="valeur"
            :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                'organe'
              )}/valeur`" />

          <Input v-show="isOrgane"
            id="nb_echantillons"
            label="Nombre d'échantillons"
            type="number"
            :options="{min:1}"
            />

        </Container>
      </Section>
      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn :to="{
              name: 'essaiExperimentalTaskVariableStudiedListEdit',
              params: { id: $route.params.id, tid: $route.params.tid }
            }"
            text="Annuler" />
          <Btn v-if="$route.name.includes('Edit')"
            btnType="submit"
            text="Enregistrer"
            color="primary" />
          <Btn v-else
            btnType="submit"
            text="Enregistrer"
            color="primary" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Radio from '@/components/form/Radio.vue'
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import FormService from '@/services/form.service'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Input from '@/components/form/Input.vue'

export default {
  name: 'VariablesStudiedEntryLevelSubview',
  components: {
    SelectExtended,
    Radio,
    Input,
    Btn,
    Container,
    Section,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    essai: {
      type: Object,
    },
    variableProps: {
      type: Object,
    },
    notation: {
      type: Object,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data(props) {
    const FS = new FormService()
    FS.setEmitter(this.emitter)
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      saisie_niveau_uid: this.yup.object().nullable(),
      organe: this.yup.object().nullable(),
      nb_echantillons: this.yup.string().nullable(),
    })

    const { errors, isSubmitting } = FS.initFrom(
      validationSchema,
    )
    const onSubmit = FS.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      FS,
      organesDraggables: this.$route.name.includes('Edit'),
      saisie_levels: [],
      saisie_endpoint: 'dictionnaire/saisie/niveau',
      method: 'put',
      errors,
      isSubmitting,
      onSubmit,
      variable: props.variableProps,
      organes: [],
      schema: {},
    }
  },

  computed: {
    isOrgane() {
      return this.variable.isOrgane
    },
  },
  mounted() {
    this.getLevels()
    if (this.$route.name.includes('Add')) {
      this.variable.isOrgane = false
    }

    this.schema = this.FS.populateShema(this.variable)

    this.variable.organes.forEach((organe) => {
      this.schema.nb_echantillons = organe.nb_echantillons
      this.schema.organe = { key: organe?.organe?.id, value: organe?.organe?.valeur }
    })

    if (this.variable.id) {
      this.variable.isOrgane = this.variable?.saisie_niveau?.uid === 'ORGANE'
      this.schema.saisie_niveau_uid = this.variable.saisie_niveau?.uid
    }

    this.FS.setFormValues(this.schema)
  },

  methods: {
    getValeursToUpdate(items = []) {
      return items.filter((item) => 'id' in item)
    },

    getValeursToCreate(items = []) {
      return items.filter((item) => item.id === undefined)
    },

    getValeursToDelete(items = []) {
      if (this.variable?.organes) {
        return this.variable.organes.filter((item) => {
          const ids = items.map((i) => i.id)
          return !ids.includes(item.id)
        })
      }

      return []
    },

    toggleIsOrgane(event) {
      this.variable.isOrgane = event === 'ORGANE'
    },

    handleSubmit(values) {
      const { variable } = this
      const params = { ...values, ...variable }
      if (['MODALITE', 'MICROPARCELLE'].includes(params.saisie_niveau_uid)) {
        params.organes = []
      } else {
        // eslint-disable-next-line no-param-reassign
        params.organes = [
          {
            organe_id: values?.organe?.key,
            nb_echantillons: values?.nb_echantillons,
            ordre: 1,
          },
        ]
      }

      params.laboratoire = variable.laboratoire ?? false

      const organesToUpdate = this.getValeursToUpdate(params.organes)
      const organesToCreate = this.getValeursToCreate(params.organes)
      const organesToDelete = this.getValeursToDelete(params.organes)

      this.variable.organes = params.organes
      const rp = this.$route.params
      const endpoint = `essai/${rp.id}/evenement/${rp.tid}/fiche/notation/${this.notation.id}/variable_etudiee/${rp.veid}`

      this.fetchService.put(endpoint, params)
        .then((result) => {
          const veid = result.data.id
          const { success } = result.meta
          if (success) {
            if (params.organes.length > 0) {
              if (this.$route.name.includes('Add')) {
                params.organes.forEach((organe) => {
                  this.fetchService.post(`${endpoint}/niveau_saisie`, organe).then((ro) => {
                    const { id } = ro.data
                    console.log(`Organe ${id} créé`)
                  })
                })
              } else {
                organesToDelete.forEach((valeur) => {
                  this.fetchService.delete(`${endpoint}/niveau_saisie/${valeur.id}`).then(() => {
                    console.log(`Organe ${valeur.id} supprimé`)
                  })
                })
                organesToUpdate.forEach((valeur) => {
                  this.fetchService.put(`${endpoint}/niveau_saisie/${valeur.id}`, valeur).then(() => {
                    console.log(`Organe ${valeur.id} modifié`)
                  })
                })
                organesToCreate.forEach((valeur) => {
                  this.fetchService.post(`${endpoint}/niveau_saisie`, valeur).then((r) => {
                    console.log(`Organe ${r.data.id} créé`)
                  })
                })
              }
            } else if (organesToDelete.length) {
              organesToDelete.forEach((valeur) => {
                this.fetchService.delete(`${endpoint}/niveau_saisie/${valeur.id}`).then(() => {
                  console.log(`Organe ${valeur.id} supprimé`)
                })
              })
            }

            if (this.$route.name.includes('Edit')) {
              this.emitter.emit('alert', {
                type: 'success',
                content: `Le niveau de saisie de la variable étudiée - ${this.$route.params.veid} - a bien été modifié.`,
              })
              this.$router.push({
                name: 'essaiExperimentalTaskVariableStudiedEntryLevel',
                params: {
                  id: this.$route.params.id,
                  tid: this.$route.params.tid,
                  veid,
                },
              })
            } else {
              this.emitter.emit('alert', {
                type: 'success',
                content: `Le niveau de saisie de la variable étudiée - ${this.$route.params.veid} - a bien été créé.`,
              })

              this.$router.push({
                name: 'essaiExperimentalTaskVariableStudiedListEdit',
                params: {
                  id: this.$route.params.id,
                  tid: this.$route.params.tid,
                },
              })
            }
          }
        })
    },

    getLevels() {
      switch (this.notation.saisie_objet.uid) {
        default:
        case 'MODALITE':
          this.fetchService.get(`${this.saisie_endpoint}?filters=uid:in(MODALITE)`).then(
            (res) => {
              const datas = res.data
              if (datas.length > 0) {
                this.saisie_levels = datas.map((data) => ({
                  label: data.designation,
                  value: data.uid,
                }))
              }
            },
          )
          break
        case 'PARCELLE':
          this.fetchService.get(this.saisie_endpoint).then(
            (res) => {
              const datas = res.data
              if (datas.length > 0) {
                this.saisie_levels = datas.map((data) => ({
                  label: data.designation,
                  value: data.uid,
                  id: data.id,
                }))
                this.saisie_levels.sort((a, b) => a.id - b.id)
              }
            },
          )
          break
      }
      return this.saisie_levels
    },
  },
}
</script>

<style lang="scss" scoped></style>
