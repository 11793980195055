<template>
  <!--   <pre><code>{{ JSON.stringify(notation, null, 2) }}</code></pre> -->
  <div>
    <form @submit.prevent="onSubmit()">
      <Section>
        <Container>
          <h2 class="section-title">Définition</h2>
          <Radio required id="variablepersonnalisee" :items="[
            { label: 'Prédéfinie', value: false },
            { label: 'Personalisée', value: true },
          ]" inputStyle="inline" @update:modelValue="changeDef($event)" />
          <Input  v-show="isUndefined" required id="designation" label="Désignation" />
          <SelectExtended v-show="!isUndefined" required id="variable_uid" label="Variable prédéfinie" option-key="uid"
            option-value="designation" api-endpoint="variable?sort=designation.ASC" class="max-width-sm" />

            <SelectExtended v-show="isUndefined" id="type_uid" label="Type de variable" option-key="uid" required
            option-value="designation" api-endpoint="dictionnaire/type/variable" @update:modelValue="changeType($event)"
            class="max-width-sm" />

            <Input v-show="typeIsNumeric && isUndefined" id="valeur_min" label="Valeur min." class="max-width-xxs" />
          <Input v-show="typeIsNumeric && isUndefined" id="valeur_max" label="Valeur max." class="max-width-xxs" />

          <SelectExtended v-show="typeIsNumeric && isUndefined" id="unite_uid" label="Unité" option-key="uid"
            option-value="valeur" api-endpoint="unite" required class="max-width-sm" />

          <RepeatableInputs v-show="typeIsList && isUndefined" required v-if="repeatables.valeurs && repeatables.valeurs.default"
            label="Valeurs" id="valeurs" :errors="errors" :default-selection="repeatables.valeurs.default"
            :items="repeatables.valeurs.items" :n-cols="1" />

          <RepeatableInputs v-show="typeIsList && isUndefined" v-else-if="repeatables.valeurs" label="Valeurs"
            id="valeurs" :errors="errors" :items="repeatables.valeurs.items" required :n-cols="1" />
          <Radio v-show="isUndefined" id="laboratoire" :items="[
            { label: 'En laboratoire', value: true },
            { label: 'Sur le terrain', value: false },
          ]" label="Localisation de la notation" required inputStyle="inline" />
        </Container>
      </Section>
      <Section>
        <Container>
          <h2 class="section-title">Associer une cible</h2>
          <SelectExtended id="cible_id" label="Cible" option-key="id" option-value="valeur"
            :api-endpoint="`dictionnaire/referentiel/${ruid}/valeur?sort=valeur.ASC`" class="max-width-sm" />
        </Container>
      </Section>
      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn :to="{
            name: 'essaiExperimentalTaskVariableStudiedListEdit',
            params: { id: $route.params.id, tid: $route.params.tid }
          }" text="Annuler" />
          <Btn v-if="$route.name.includes('Edit')" btnType="submit" text="Enregistrer" color="primary" />
          <Btn v-else btnType="submit" text="Suivant" color="primary" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Radio from '@/components/form/Radio.vue'
import Input from '@/components/form/Input.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import RepeatableInputs from '@/components/form/RepeatableInputs.vue'
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import FormService from '@/services/form.service'

export default {
  name: 'VariablesStudiedSettingsSubview',
  components: {
    Radio,
    Input,
    SelectExtended,
    RepeatableInputs,
    Btn,
    Container,
    Section,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    essai: {
      type: Object,
    },
    variableProps: {
      type: Object,
    },
    notation: {
      type: Object,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data(props) {
    const FS = new FormService()
    FS.setEmitter(this.emitter)
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      cible_id: this.yup.object().nullable(),
      variablepersonnalisee: this.yup.boolean().nullable(),
      variable_uid: this.yup.object().when(['variablepersonnalisee'], (variablepersonnalisee, schema) => (
        !variablepersonnalisee ? schema.required() : schema.nullable()
      ))
        .nullable(),
      designation: this.yup.string().when(['variablepersonnalisee'], (variablepersonnalisee, schema) => (
        variablepersonnalisee ? schema.required() : schema.nullable()

      ))
        .nullable(),
      type_uid: this.yup.object().when(['variablepersonnalisee'], (variablepersonnalisee, schema) => (
        variablepersonnalisee ? schema.required() : schema.nullable()

      ))
        .nullable(),
      // eslint-disable-next-line
      unite_uid: this.yup.object().nullable(),
      valeur_min: this.yup.string().nullable(),
      valeur_max: this.yup.string().nullable(),
      laboratoire: this.yup.boolean().nullable(),
      valeurs: this.yup.array().of(
        this.yup.object().shape({
          designation: this.yup.string().nullable(),
        }),
      ).nullable(),
    })

    const { errors, isSubmitting } = FS.initFrom(
      validationSchema,
    )
    const onSubmit = FS.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      FS,
      ruid: this.helperService.getReferentialByUid('cible'),
      method: 'post',
      errors,
      isSubmitting,
      onSubmit,
      variable: props.variableProps,
      repeatables: {
        valeurs: {
          items: {
            group: [
              {
                label: 'Désignation',
                name: 'designation',
              },
            ],
          },
        },
      },
      schema: null,
      vId: this.$route.params.veid ?? null,
    }
  },

  computed: {
    isUndefined() {
      if (typeof this.variable.isUndefined === 'string') {
        return this.variable.isUndefined === 'true'
      }
      return this.variable.isUndefined
    },
    typeIsList() {
      return this.variable.isList
    },
    typeIsNumeric() {
      return this.variable.isNumeric
    },
    type: {
      get() {
        return true
      },
      set(v) {
        this.variable.isNumeric = false
        this.variable.isList = false
        if (v.key === 'DECIMAL') {
          this.variable.isNumeric = true
        }
        if (v.key === 'LIST') {
          this.variable.isList = true
        }
      },
    },
  },
  mounted() {
    if (!this.variable.id) {
      this.changeDef(false)
      this.variable.variablepersonnalisee = this.variable.isUndefined
    } else {
      this.method = 'put'
      this.changeDef(this.variable.variablepersonnalisee)
    }

    this.variable.laboratoire = false
    this.schema = this.FS.populateShema(this.variable)

    this.repeatables.valeurs.default = this.variable.valeurs.map((valeur) => ({
      id: valeur.id,
      designation: valeur.designation,
    }))
    this.variable.isNumeric = this.variable?.type?.uid === 'DECIMAL'
    this.variable.isList = this.variable?.type?.uid === 'LIST'

    if (this.variable.id) {
      this.schema.type_uid = {
        key: this.variable?.type?.uid,
        value: this.variable?.type?.designation,
      }

      this.schema.cible_id = {
        key: this.variable.cible?.id,
        value: this.variable.cible?.valeur,
      }

      this.schema.unite_uid = {
        key: this.variable?.unite?.uid,
        value: this.variable?.unite?.valeur,
      }
      this.schema.variable_uid = this.variable?.variable?.uid ? {
        key: this.variable?.variable?.uid,
        value: this.variable?.variable?.designation,
      } : null
    }
    this.FS.setFormValues(this.schema)
  },
  methods: {
    changeType(event) {
      this.type = event
    },
    changeDef(event) {
      this.variable.isUndefined = event
    },
    getValeursToUpdate(items = []) {
      return items.filter((item) => 'id' in item)
    },
    getValeursToCreate(items = []) {
      return items.filter((item) => item.id === undefined)
    },
    getValeursToDelete(items = []) {
      if (this.variable?.valeurs) {
        return this.variable.valeurs.filter((item) => {
          const ids = items.map((i) => i.id)
          return !ids.includes(item.id)
        })
      }

      return []
    },
    handleSubmit(values) {
      const params = { ...values }
      if (this.isUndefined === false) {
        if (!params.designation) {
          params.designation = values.variable_uid?.value
        }

        params.valeur_min = null
        params.valeur_max = null
        params.unite_uid = null
        params.valeurs = undefined
        params.type_uid = null
        params.laboratoire = false
        params.variable_uid = values?.variable_uid?.key
        params.designation = values.variable_uid?.value
      } else {
        params.variable_uid = null
        params.type_uid = values?.type_uid?.key
        params.unite_uid = values.unite_uid?.key

        if (values?.type_uid?.key === 'DECIMAL' && values.unite_uid?.key === undefined) {
          this.FS.setFieldError('unite_uid', 'Le champ est obligatoire.')
          // eslint-disable-next-line
          return false
        }

        if (values?.type_uid?.key === 'LIST') {
          const lovalValue = []
          values.valeurs.forEach((val) => {
            if (val.designation !== null) {
              lovalValue.push(val)
            }
          })
          if (lovalValue.length === 0) {
            this.FS.setFieldError('valeurs', 'Le champ est obligatoire.')
            // eslint-disable-next-line
          return false
          }
        }
      }

      if (values.cible_id?.key) {
        params.cible_id = values.cible_id?.key
      } else {
        params.cible_id = null
      }

      params.saisie_niveau_uid = 'MODALITE'
      if (this.notation.saisie_objet.uid === 'PARCELLE') {
        params.saisie_niveau_uid = 'MICROPARCELLE'
      }

      const rp = this.$route.params
      let endpoint = `essai/${rp.id}/evenement/${rp.tid}/fiche/notation/${this.notation.id}/variable_etudiee`
      if (this.variable.id) {
        endpoint = `essai/${rp.id}/evenement/${rp.tid}/fiche/notation/${this.notation.id}/variable_etudiee/${rp.veid}`
      }

      const valeursToUpdate = this.getValeursToUpdate(params.valeurs)
      const valeursToCreate = this.getValeursToCreate(params.valeurs)
      const valeursToDelete = this.getValeursToDelete(params.valeurs)

      this.fetchService[this.method](endpoint, params).then((res) => {
        const { id } = res.data
        if (id) {
          let ep = `${endpoint}/${id}/valeur`
          if (this.method === 'put') {
            ep = `${endpoint}/valeur`
          }

          if (params.valeurs && values?.type_uid?.key === 'LIST') {
            if (this.method === 'post') {
              params.valeurs.forEach((valeur) => {
                this.fetchService.post(ep, valeur).then((r) => {
                  console.log(`Valeur ${r.data.id} créée`)
                })
              })
            } else {
              valeursToDelete.forEach((valeur) => {
                this.fetchService.delete(`${ep}/${valeur.id}`).then(() => {
                  console.log(`Valeur ${valeur.id} supprimée`)
                })
              })
              valeursToUpdate.forEach((valeur) => {
                this.fetchService.put(`${ep}/${valeur.id}`, valeur).then(() => {
                  console.log(`Valeur ${valeur.id} modifiée`)
                })
              })
              valeursToCreate.forEach((valeur) => {
                this.fetchService.post(ep, valeur).then((r) => {
                  console.log(`Valeur ${r.data.id} créée`)
                })
              })
            }
          } else if (valeursToDelete.length) {
            valeursToDelete.forEach((valeur) => {
              this.fetchService.delete(`${ep}/${valeur.id}`).then(() => {
                console.log(`Valeur ${valeur.id} supprimée`)
              })
            })
          }

          if (this.$route.name.includes('Edit')) {
            this.emitter.emit('alert', {
              type: 'success',
              content: `La définition de la variable étudiée - ${this.$route.params.veid} - a bien été modifiée.`,
            })
            this.$router.push({
              name: 'essaiExperimentalTaskVariableStudiedSettings',
              params: {
                id: this.$route.params.id,
                tid: this.$route.params.tid,
                veid: id,
              },
            })
          } else {
            if (this.$route.params.veid) {
              this.emitter.emit('alert', {
                type: 'success',
                content: `La définition de la variable étudiée - ${this.$route.params.veid} - a bien été modifiée.`,
              })
            } else {
              this.emitter.emit('alert', {
                type: 'success',
                content: 'La définition de la variable étudiée a bien été créée.',
              })
            }

            this.$router.push({
              name: 'essaiExperimentalTaskVariableStudiedEntryLevelAdd',
              params: {
                id: this.$route.params.id,
                tid: this.$route.params.tid,
                veid: id,
              },
            })
          }
        }
      }, (responseError) => {
        this.FS.handleApiError(responseError)
      })
      return true
    },
  },
}
</script>

<style lang="scss" scoped></style>
