<template>
  <template v-if="$route.name.includes('Add')">
    <Section class="section--border-bottom bg-gray-lightestest">
      <Container>
        <Timeline
          v-if="$route.name.includes('Settings')"
          :index="0"
          :total="2"
          :lastStepToRight="true"
          :stepsData="stepsData"
        />
        <Timeline
          v-else
          :index="1"
          :total="2"
          :lastStepToRight="true"
          :stepsData="stepsData"
        />
      </Container>
    </Section>
    <Section>
        <template v-if="$route.name.includes('Settings')">
          <VariableStudiedSettingsSubview
            v-if="fetched"
            v-model="submit"
            :task="task"
            :variableProps="variable"
            :notation="notation"
            @variable-setted="loadVariable($route.params, $event)"
          />
        </template>
        <template v-else>
          <VariableStudiedEntryLevelSubview
            v-if="fetched"
            v-model="submit"
            :task="task"
            :variableProps="variable"
            :notation="notation"
          />
        </template>

    </Section>
  </template>
  <template v-else>
    <Section class="section--no-padding">
      <Tabs v-model="variableTabs" layout="simple"
      >
        <form @submit.prevent="onSubmit()">
        <Tab title="Définition">
          <VariableStudiedSettingsSubview
            v-if="fetched"
            v-model="submit"
            :task="task"
            :variableProps="variable"
            :notation="notation"
          />
        </Tab>
        <Tab title="Niveau de saisie">
          <VariableStudiedEntryLevelSubview
            v-if="fetched"
            v-model="submit"
            :task="task"
            :variableProps="variable"
            :notation="notation"
          />
        </Tab>
        </form>
      </Tabs>
    </Section>
  </template>

  <Teleport to="#view-header__teleport">
    <div
      class="view-header view-header--tasks-notations--edit">
      <div class="view-header__header">
        <Btn
          v-if="$route.name.includes('Edit')"
          text="Supprimer la variable"
          color="primary"
          icon="trash-alt"
          @click="openModal('delete', variable)"
        />
      </div>
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{
          name: 'essaiExperimentalTaskVariableStudiedListEdit',
          params: {
            id: $route.params.id,
            tid: $route.params.tid
          }
        }"
        />
        <template v-if="task">
          <h1 v-if="$route.name.includes('Edit')" class="page-title">
            {{ pageTitle }} - {{ variable.designation }}
            <template v-if="variable?.dat_evtprotocole_variableetudiee_id">
              (héritée)
            </template>
          </h1>
          <h1 v-else class="page-title">
            {{ pageTitle }} - Création</h1>
        </template>
      </div>
    </div>
  </Teleport>

  <Modal
    title="Supprimer la variable"
    :active="modal.delete"
    :data="modalData"
    @modal-close="modal.delete = false"
  >
    <template v-slot:modal-body="data">
      <p v-if="data?.data?.dat_evtprotocole_variableetudiee_id !== null" class="color-warning">
        <b>Attention, la variable est héritée d'un protocole parent.</b>
      </p>
      <p>
        Voulez vous vraiment supprimer la variable?
      </p>

      <p class="color-warning"><b>Cela peut affecter les variables calculées.</b></p>

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.delete = false" />
      <Btn
        text="Supprimer"
        @click="deleteVariable()"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>

</template>

<script>

import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Timeline from '@/components/layout/Timeline.vue'
import Tabs from '@/components/base/Tabs.vue'
import Tab from '@/components/base/Tab.vue'
import VariableStudiedSettingsSubview from '@/views/essais/ExperimentalTask/AddEditSubviews/VariableStudiedSettingsSubview.vue'
import VariableStudiedEntryLevelSubview from '@/views/essais/ExperimentalTask/AddEditSubviews/VariableStudiedEntryLevelSubview.vue'
import Btn from '@/components/base/Btn.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'VariableStudiedSubview',
  components: {
    Container,
    Section,
    Timeline,
    Tabs,
    Tab,
    VariableStudiedSettingsSubview,
    VariableStudiedEntryLevelSubview,
    Btn,
    Modal,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    essai: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const editRoutes = [
      'essaiExperimentalTaskVariableStudiedSettingsEdit',
      'essaiExperimentalTaskVariableStudiedEntryLevelEdit',
    ]

    const variableTabs = editRoutes.findIndex((r) => r === this.$route.name)
    return {
      modal: {
        delete: false,
      },
      modalData: {},
      fetched: false,
      notation: {},
      editRoutes,
      submit: false,
      variable: {
        valeurs: [],
        organes: [],
      },
      variableTabs,
      step: 0,
      stepsData: [
        'Définition',
        'Niveau de saisie',
      ],
    }
  },

  watch: {
    variableTabs() {
      this.$router.push({
        name: this.editRoutes[this.variableTabs],
        params: {
          id: this.$route.params.id,
          tid: this.$route.params.tid,
          veid: this.$route.params.veid,
        },
      })
    },
    // eslint-disable-next-line func-names
    '$route.name': function () {
      if (this.$route.name !== 'essaiExperimentalTaskVariableStudiedListEdit') {
        this.loadVariable(this.$route.params)
      }
    },
  },

  mounted() {
    this.loadVariable(this.$route.params)
  },

  methods: {
    deleteVariable() {
      this.emitter.emit('open-loader')
      const { params } = this.$route
      this.fetchService
        .delete(`essai/${params.id}/evenement/${params.tid}/fiche/notation/${this.notation.id}/variable_etudiee/${params.veid}`)
        .then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: `La variable ${params.veid} a été supprimée avec succès`,
            })

            this.$router.push({
              name: 'essaiExperimentalTaskVariableStudiedListEdit',
              params: {
                id: params.id,
                tid: params.tid,
              },
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )

      this.emitter.emit('close-loader')
      this.modal.delete = false
    },

    openModal(modal, datas) {
      this.modalData = datas
      this.modal[modal] = true
    },

    loadVariable(params, event) {
      let veId
      if (event) {
        veId = event
      } else {
        veId = this.$route.params?.veid
      }

      this.fetchService.get(
        `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`,
      ).then((res) => {
        this.notation = res.data.pop()
        if (!this.$route.params.veid && !veId) {
          this.fetched = true
        } else if (veId) {
          this.fetched = false
          this.fetchVariable(params, veId)
        } else {
          this.fetchVariable(params, veId)
        }
      })
    },

    fetchVariable(params, veId) {
      this.fetchService.get(
        `essai/${params.id}/evenement/${params.tid}/fiche/notation/${this.notation.id}/variable_etudiee/${veId}`,
      ).then(
        (variable) => {
          this.variable = variable.data
          if (this.variable.id) {
            const { id } = this.variable
            this.fetchService.get(
              `essai/${params.id}/evenement/${params.tid}/fiche/notation/${this.notation.id}/variable_etudiee/${id}/valeur`,
            ).then((valeurs) => {
              this.variable.valeurs = valeurs.data.map((valeur) => ({
                id: valeur.id,
                designation: valeur.designation,
              }))
              return this.variable
            }).then(() => {
              this.fetchService.get(
                `essai/${params.id}/evenement/${params.tid}/fiche/notation/${this.notation.id}/variable_etudiee/${id}/niveau_saisie?sort=ordre.ASC&limit=0`,
              ).then((organes) => {
                this.variable.organes = organes.data.map((organe) => ({
                  id: organe.id,
                  designation: organe.designation,
                  organe: organe.organe,
                  ordre: organe.ordre,
                  nb_echantillons: organe.nb_echantillons,
                }))
                this.fetched = true
              })
            })
          }
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
